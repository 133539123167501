import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HotjarService {
  callEvent(eventName: string): void {
    if (!environment.production) {
      console.log('Hotjar is disabled in development mode.');
      return;
    }

    if (typeof (window as any).hj === 'function') {
      (window as any).hj('event', eventName);
    } else {
      console.warn('.. script is not loaded yet!');
    }
  }
}
