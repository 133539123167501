<div
  #triggerEl
  class="search-multi-selector"
  [matMenuTriggerFor]="!disabled ? multiSelector : null"
  (click)="handleClick($event)"
  [ngClass]="{
    active: opened || focused,
    disabled: disabled,
    errors: errors && errorMessage && touched
  }"
>
  <div
    class="input-wrapper"
    [ngClass]="{
      active: opened || focused,
      disabled: disabled
    }"
  >
    <div class="selected">
      <div class="value" *ngFor="let option of selected">
        <span>{{ option.value }}</span>
        <app-shared-icon
          name="close"
          class="remove-icon"
          [size]="8"
          (click)="removeValue(option.key)"
        ></app-shared-icon>
      </div>
    </div>
    <input
      *ngIf="focused || !!searchValue?.trim() || !selected?.length"
      #inputElement
      [(ngModel)]="searchValue"
      (focus)="focused = true"
      (blur)="focused = false"
      (ngModelChange)="onSearchChange($event)"
      [disabled]="disabled"
      [ngClass]="{ disabled: disabled }"
    />
  </div>

  <mat-menu
    #multiSelector="matMenu"
    class="options-dropdown"
    [yPosition]="yPosition"
  >
    <div class="options-dropdown-wrapper">
      <div *ngIf="loading" class="item">{{ loading }}</div>
      <div
        *ngIf="
          !loading && !options.length && searchValue.length < 3 && placeholder
        "
        class="item"
      >
        {{ placeholder }}
      </div>
      <div
        *ngIf="!loading && searchValue.length >= 3"
        class="item create-new"
        (click)="addCustomValue()"
      >
        Press Enter to add “{{ searchValue }}” as a new item
      </div>
      <ul (click)="$event.stopPropagation()">
        <li
          *ngFor="let option of options"
          [ngClass]="{ selected: isSelected(option.key) }"
          (click)="toggleValue(option.key)"
        >
          <div>
            <div [innerHTML]="option.value"></div>
            <div
              *ngIf="option.desc"
              [innerHTML]="option.desc"
              class="desc"
            ></div>
          </div>
          <app-shared-icon
            name="check"
            class="check-icon"
            [size]="20"
            *ngIf="isSelected(option.key)"
          ></app-shared-icon>
        </li>
      </ul>
    </div>
  </mat-menu>
</div>

<div
  *ngIf="errors && errorMessage && touched"
  class="input-error-message multi-selector-error"
>
  {{ errorMessage }}
</div>
