<form [formGroup]="accountSetupForm" (ngSubmit)="submitHandler()">
  <div *ngIf="loadingSubmarkets" class="mb-10">
    <app-shared-loading position="left"></app-shared-loading>
  </div>

  <ng-container *ngIf="!loadingSubmarkets">
    <app-shared-multi-selector
      name="roles"
      label="My roles"
      [focused]="false"
      [disabled]="loading"
      [options]="options['roles']"
      [selected]="[]"
      (handleChange)="handleRoles($event)"
      [errors]="accountSetupForm.get('roles')?.errors"
      [touched]="accountSetupForm.get('roles')?.touched"
    ></app-shared-multi-selector>

    <!-- <app-shared-multi-selector
      name="markets"
      label="Interested markets"
      [disabled]="loading"
      [options]="options['markets']"
      [selected]="[]"
      (handleChange)="handleMarkets($event)"
      [errors]="accountSetupForm.get('markets')?.errors"
      [touched]="accountSetupForm.get('markets')?.touched"
    ></app-shared-multi-selector> -->

    <app-shared-multi-selector
      name="submarketIds"
      label="Markets"
      [disabled]="loading"
      [options]="options['submarkets']"
      [selected]="[]"
      (handleChange)="handleSubmarketIds($event)"
      [errors]="accountSetupForm.get('submarketIds')?.errors"
      [touched]="accountSetupForm.get('submarketIds')?.touched"
      [searchable]="true"
    ></app-shared-multi-selector>

    <app-shared-multi-selector
      name="investmentStyles"
      label="My investment style"
      [disabled]="loading"
      [options]="options['investmentStyles']"
      [selected]="[]"
      (handleChange)="handleInvestmentStyles($event)"
      [errors]="accountSetupForm.get('investmentStyles')?.errors"
      [touched]="accountSetupForm.get('investmentStyles')?.touched"
    ></app-shared-multi-selector>

    <app-shared-multi-selector
      name="rehabComfortabilities"
      label="Interest in rehab projects"
      [disabled]="loading"
      [options]="options['rehabComfortabilities']"
      [selected]="[]"
      (handleChange)="handleRehabComfortabilities($event)"
      [errors]="accountSetupForm.get('rehabComfortabilities')?.errors"
      [touched]="accountSetupForm.get('rehabComfortabilities')?.touched"
    ></app-shared-multi-selector>

    <app-account-setup-modal-form-investor
      (selectedChange)="handleEntities($event)"
      [errors]="accountSetupForm.get('entities')?.errors"
      [touched]="accountSetupForm.get('rehabComfortabilities')?.touched"
      [disabled]="loading"
    ></app-account-setup-modal-form-investor>

    <app-shared-button [loading]="loading">Next</app-shared-button>
  </ng-container>
</form>
