import { Component, OnInit } from '@angular/core';

import { HotjarService } from '@core/services/hotjar.service';

@Component({
  selector: 'app-auth-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.scss'],
})
export class AccountSetupComponent implements OnInit {
  constructor(private hotjarService: HotjarService) {}

  ngOnInit(): void {
    this.hotjarService.callEvent('start_account_setup');
  }
}
