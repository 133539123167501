import { gql, TypedDocumentNode } from 'apollo-angular';

import { IListSubmarketsData } from '@shared/models/account-preferences';

export const LIST_SUBMARKETS: TypedDocumentNode<IListSubmarketsData> = gql`
  query listSubmarkets {
    listSubmarkets {
      id
      name
      displayName
    }
  }
`;
