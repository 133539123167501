import { Component, OnInit } from '@angular/core';

import { HotjarService } from '@core/services/hotjar.service';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { setAuthModal } from '@state/auth/auth.actions';

@Component({
  selector: 'app-auth-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignUpComponent implements OnInit {
  constructor(
    public store: Store<AppState>,
    private hotjarService: HotjarService
  ) {}

  ngOnInit(): void {
    this.hotjarService.callEvent('start_sign_up');
  }

  openSignIn() {
    this.store.dispatch(setAuthModal({ authModal: 'signin' }));
  }
}
