import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shared-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() text?: string;
  @Input() position?: 'left' | 'center' = 'center';
}
