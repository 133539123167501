import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  ISignInRes,
  ISignInInput,
  ISignUpRes,
  ISignUpInput,
  IPasswordResetWithPasswordInput,
  IConfirmNumberInput,
  IConfirmNumberData,
  IPasswordResetWithPasswordData,
} from '@shared/models/auth';
import {
  IUpdatePhoneNumberInput,
  IUpdatePhoneNumberData,
} from '@shared/models/profile';

import { MUTATION_ERROR_FRAGMENT } from '../errors.fragments';
import { USER_PREFERENCE_FRAGMENT } from './user.mutation';

export const USER_FRAGMENT = gql`
  ${USER_PREFERENCE_FRAGMENT}
  fragment UserFragment on User {
    id
    firstName
    surname
    email
    phoneNumber
    roles
    confirmedAt
    isActive
    referralCode
    insertedAt
    submarkets {
      id
      name
      displayName
    }
    preference {
      ...UserPreferenceFragment
    }
  }
`;

export const SIGN_IN: TypedDocumentNode<ISignInRes, ISignInInput> = gql`
  mutation signInWithPassword($email: String!, $password: String!) {
    signInWithPassword(email: $email, password: $password) {
      id
      token
      firstName
      surname
      email
      phoneNumber
      roles
      confirmedAt
      referralCode
      isActive
    }
  }
`;

export const SIGN_UP: TypedDocumentNode<ISignUpRes, ISignUpInput> = gql`
  ${USER_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation registerWithPassword($input: RegisterWithPasswordInput!) {
    registerWithPassword(input: $input) {
      metadata {
        token
      }
      result {
        ...UserFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;

export const PASSWORD_RESET_WITH_PASSWORD: TypedDocumentNode<
  IPasswordResetWithPasswordData,
  IPasswordResetWithPasswordInput
> = gql`
  ${MUTATION_ERROR_FRAGMENT}
  mutation passwordResetWithPassword(
    $email: String!
    $input: PasswordResetWithPasswordInput!
  ) {
    passwordResetWithPassword(email: $email, input: $input) {
      metadata {
        token
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;

export const CONFIRM_PHONE_NUMBER: TypedDocumentNode<
  IConfirmNumberData,
  IConfirmNumberInput
> = gql`
  ${USER_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation confirmPhoneNumber($input: ConfirmPhoneNumberInput!) {
    confirmPhoneNumber(input: $input) {
      result {
        ...UserFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;

export const UPDATE_PHONE_NUMBER: TypedDocumentNode<
  IUpdatePhoneNumberData,
  IUpdatePhoneNumberInput
> = gql`
  ${USER_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation updatePhoneNumber($input: UpdatePhoneNumberInput!) {
    updatePhoneNumber(input: $input) {
      result {
        ...UserFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;
