interface IMultiSelectOption {
  key: string;
  value: string;
}

export interface IPreferencesOptions {
  [key: string]: IMultiSelectOption[];
}

export const OPTIONS_MARKETS: IMultiSelectOption[] = [
  { key: 'AUSTIN', value: 'Austin' },
  { key: 'BIRMINGHAM', value: 'Birmingham' },
  { key: 'CHATTANOOGA', value: 'Chattanooga' },
  { key: 'CINCINNATI', value: 'Cincinnati' },
  { key: 'HUNTSVILLE', value: 'Huntsville' },
  { key: 'KNOXVILLE', value: 'Knoxville' },
  { key: 'LEXINGTON', value: 'Lexington' },
  { key: 'LOUISVILLE', value: 'Louisville' },
  { key: 'MEMPHIS', value: 'Memphis' },
  { key: 'NASHVILLE', value: 'Nashville' },
  { key: 'PHILADELPHIA', value: 'Philadelphia' },
  { key: 'SAN_ANTONIO', value: 'San Antonio' },
  {
    key: 'NEW_YORK_NEWARK_JERSEY_CITY_NY_NJ_PA',
    value: 'New York-Newark-Jersey City, NY-NJ-PA',
  },
  {
    key: 'INDIANAPOLIS_CARMEL_ANDERSON_IN',
    value: 'Indianapolis-Carmel-Anderson, IN',
  },
  { key: 'TULSA_OK', value: 'Tulsa, OK' },
  {
    key: 'MINNEAPOLIS_ST_PAUL_BLOOMINGTON_MN_WI',
    value: 'Minneapolis-St. Paul-Bloomington, MN-WI',
  },
  { key: 'DETROIT_WARREN_DEARBORN_MI', value: 'Detroit-Warren-Dearborn, MI' },
  { key: 'JACKSON_MS', value: 'Jackson, MS' },
  { key: 'KANSAS_CITY_MO_KS', value: 'Kansas City, MO-KS' },
  {
    key: 'TAMPA_ST_PETERSBURG_CLEARWATER_FL',
    value: 'Tampa-St. Petersburg-Clearwater, FL',
  },
  { key: 'PITTSBURGH_PA', value: 'Pittsburgh, PA' },
  { key: 'OKLAHOMA_CITY_OK', value: 'Oklahoma City, OK' },
  { key: 'SPRINGFIELD_MO', value: 'Springfield, MO' },
  { key: 'ST_LOUIS_MO_IL', value: 'St. Louis, MO-IL' },
  { key: 'TOPEKA_KS', value: 'Topeka, KS' },
  { key: 'MACON', value: 'Macon' },
  {
    key: 'ATLANTA_SANDY_SPRINGS_ROSWELL_GA',
    value: 'Atlanta-Sandy Springs-Roswell, GA',
  },
  { key: 'COLUMBUS_OH', value: 'Columbus, OH' },
  { key: 'CLEVELAND_ELYRIA_OH', value: 'Cleveland-Elyria, OH' },
  {
    key: 'DALLAS_FORT_WORTH_ARLINGTON_TX',
    value: 'Dallas-Fort Worth-Arlington, TX',
  },
  { key: 'WICHITA_KS', value: 'Wichita, KS' },
  {
    key: 'ORLANDO_KISSIMMEE_SANFORD_FL',
    value: 'Orlando-Kissimmee-Sanford, FL',
  },
  {
    key: 'CHARLOTTE_CONCORD_GASTONIA_NC_SC',
    value: 'Charlotte-Concord-Gastonia, NC-SC',
  },
  { key: 'ROCHESTER_MN', value: 'Rochester, MN' },
  { key: 'EVANSVILLE_IN_KY', value: 'Evansville, IN-KY' },
  { key: 'MCALLEN_EDINBURG_MISSION_TX', value: 'McAllen-Edinburg-Mission, TX' },
  { key: 'TOLEDO_OH', value: 'Toledo, OH' },
  { key: 'FLINT_MI', value: 'Flint, MI' },
  { key: 'PHILADELPHIA_MS', value: 'Philadelphia, MS' },
  {
    key: 'ALLENTOWN_BETHLEHEM_EASTON_PA_NJ',
    value: 'Allentown-Bethlehem-Easton, PA-NJ',
  },
  { key: 'LANSING_EAST_LANSING_MI', value: 'Lansing-East Lansing, MI' },
  { key: 'MUSKEGON_MI', value: 'Muskegon, MI' },
  { key: 'RALEIGH_NC', value: 'Raleigh, NC' },
  { key: 'ERIE_PA', value: 'Erie, PA' },
  { key: 'AKRON_OH', value: 'Akron, OH' },
  { key: 'WINSTON_SALEM_NC', value: 'Winston-Salem, NC' },
  { key: 'POTTSVILLE_PA', value: 'Pottsville, PA' },
  { key: 'ODESSA_TX', value: 'Odessa, TX' },
  { key: 'OXFORD_MS', value: 'Oxford, MS' },
  { key: 'GREENSBORO_HIGH_POINT_NC', value: 'Greensboro-High Point, NC' },
  {
    key: 'AUGUSTA_RICHMOND_COUNTY_GA_SC',
    value: 'Augusta-Richmond County, GA-SC',
  },
  { key: 'CANTON_MASSILLON_OH', value: 'Canton-Massillon, OH' },
  { key: 'SAVANNAH_GA', value: 'Savannah, GA' },
  { key: 'FORT_WAYNE_IN', value: 'Fort Wayne, IN' },
  { key: 'HARRISBURG_CARLISLE_PA', value: 'Harrisburg-Carlisle, PA' },
  { key: 'READING_PA', value: 'Reading, PA' },
  {
    key: 'MIAMI_FORT_LAUDERDALE_WEST_PALM_BEACH_FL',
    value: 'Miami-Fort Lauderdale-West Palm Beach, FL',
  },
  { key: 'FAYETTEVILLE_NC', value: 'Fayetteville, NC' },
  { key: 'EL_PASO_TX', value: 'El Paso, TX' },
  { key: 'AMARILLO_TX', value: 'Amarillo, TX' },
  { key: 'STARKVILLE_MS', value: 'Starkville, MS' },
  { key: 'PORT_ST_LUCIE_FL', value: 'Port St. Lucie, FL' },
  {
    key: 'PALM_BAY_MELBOURNE_TITUSVILLE_FL',
    value: 'Palm Bay-Melbourne-Titusville, FL',
  },
  { key: 'LAFAYETTE_WEST_LAFAYETTE_IN', value: 'Lafayette-West Lafayette, IN' },
  { key: 'BROWNSVILLE_HARLINGEN_TX', value: 'Brownsville-Harlingen, TX' },
  { key: 'HOMOSASSA_SPRINGS_FL', value: 'Homosassa Springs, FL' },
  {
    key: 'NAPLES_IMMOKALEE_MARCO_ISLAND_FL',
    value: 'Naples-Immokalee-Marco Island, FL',
  },
  { key: 'GRAND_RAPIDS_WYOMING_MI', value: 'Grand Rapids-Wyoming, MI' },
  { key: 'DURHAM_CHAPEL_HILL_NC', value: 'Durham-Chapel Hill, NC' },
  { key: 'COLUMBIA_MO', value: 'Columbia, MO' },
  { key: 'MIDLAND_TX', value: 'Midland, TX' },
  { key: 'GAINESVILLE_GA', value: 'Gainesville, GA' },
  { key: 'COLUMBUS_GA', value: 'Columbus, GA' },
  { key: 'ROCKY_MOUNT_NC', value: 'Rocky Mount, NC' },
  { key: 'GREENVILLE_NC', value: 'Greenville, NC' },
  { key: 'HICKORY_LENOIR_MORGANTON_NC', value: 'Hickory-Lenoir-Morganton, NC' },
  { key: 'EAGLE_PASS_TX', value: 'Eagle Pass, TX' },
  { key: 'HOLLAND_MI', value: 'Holland, MI' },
  { key: 'BURLINGTON_NC', value: 'Burlington, NC' },
  { key: 'WILMINGTON_NC', value: 'Wilmington, NC' },
  { key: 'WEIRTON_STEUBENVILLE_WV_OH', value: 'Weirton-Steubenville, WV-OH' },
  {
    key: 'SEBASTIAN_VERO_BEACH_WEST_VERO_CORRIDOR_FL',
    value: 'Sebastian-Vero Beach-West Vero Corridor, FL',
  },
  { key: 'CLARION_PA', value: 'Clarion, PA' },
  { key: 'FORT_SCOTT_KS', value: 'Fort Scott, KS' },
  { key: 'DODGE_CITY_KS', value: 'Dodge City, KS' },
  { key: 'DUNCAN_OK', value: 'Duncan, OK' },
  { key: 'ATHENS_CLARKE_COUNTY_GA', value: 'Athens-Clarke County, GA' },
  { key: 'JEFFERSON_GA', value: 'Jefferson, GA' },
  { key: 'LUBBOCK_TX', value: 'Lubbock, TX' },
  { key: 'PECOS_TX', value: 'Pecos, TX' },
  { key: 'FREMONT_OH', value: 'Fremont, OH' },
  { key: 'LAWRENCE_KS', value: 'Lawrence, KS' },
  { key: 'ANN_ARBOR_MI', value: 'Ann Arbor, MI' },
  { key: 'LANCASTER_PA', value: 'Lancaster, PA' },
  { key: 'AUBURN_IN', value: 'Auburn, IN' },
  { key: 'ASHEVILLE_NC', value: 'Asheville, NC' },
  { key: 'IONIA_MI', value: 'Ionia, MI' },
  { key: 'GATLINBURG_TN', value: 'Gatlinburg, TN' },
  { key: 'MONROE_MI', value: 'Monroe, MI' },
  { key: 'MACON_GA', value: 'Macon, GA' },
  {
    key: 'VIRGINIA_BEACH_NORFOLK_NEWPORT_NEWS_VA_NC',
    value: 'Virginia Beach-Norfolk-Newport News, VA-NC',
  },
  { key: 'LAWTON_OK', value: 'Lawton, OK' },
  { key: 'MONTGOMERY_AL', value: 'Montgomery, AL' },
];

export const PREFERENCES_OPTIONS = {
  roles: [
    { key: 'LICENSED_AGENT', value: 'Licensed agent' },
    { key: 'INVESTOR', value: 'Investor' },
    { key: 'WHOLESALER', value: 'Wholesaler' },
    { key: 'LENDER', value: 'Lender' },
    { key: 'GENERAL_CONTRACTOR', value: 'General contractor' },
    { key: 'SUB_CONTRACTOR', value: 'Sub-contractor' },
  ],
  investmentStyles: [
    { key: 'FIX_AND_FLIP', value: 'Fix and flip' },
    { key: 'BUY_AND_HOLD', value: 'Buy and hold' },
    { key: 'NEW_BUILD', value: 'New build' },
  ],
  rehabComfortabilities: [
    { key: 'TURN_KEY', value: 'Turn key' },
    { key: 'COSMETIC', value: 'Cosmetic' },
    { key: 'SIGNIFICANT', value: 'Significant' },
    { key: 'GUT_REHAB', value: 'Gut rehab' },
  ],
  markets: OPTIONS_MARKETS,
};
