import { lastValueFrom, take } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '@state/app.state';
import {
  setSyncingGuestReferralsAction,
  removeGuestReferralAction,
} from '@state/properties/properties.actions';
import {
  selectGuestReferrals,
  selectSyncingGuestReferralsAction,
} from '@state/properties/properties.selectors';
import { PropertiesService } from '@core/services/properties.service';

export const syncPropertyReferrals = async (
  store: Store<AppState>,
  propertiesService: PropertiesService
) => {
  store
    .select(selectSyncingGuestReferralsAction)
    // If called from different components, it will only sync once
    .pipe(take(2))
    .subscribe((syncingGuestReferrals) => {
      // Do not sync if already syncing
      if (syncingGuestReferrals) return;
      store
        .select(selectGuestReferrals)
        .pipe(take(1))
        .subscribe(async (guestReferrals) => {
          setSyncingGuestReferralsAction({ syncing: true });

          if (guestReferrals && guestReferrals.length) {
            for (const referral of guestReferrals) {
              // Convert the Observable to a Promise
              await lastValueFrom(
                propertiesService.fetchProperty({
                  id: referral.propertyId,
                  refCode: referral.refCode,
                  campaignName: referral.campaignName,
                  entityId: referral.entityId,
                })
              );
              // Remove the referral after syncing
              store.dispatch(
                removeGuestReferralAction({
                  propertyId: referral.propertyId,
                  refCode: referral.refCode,
                  campaignName: referral.campaignName,
                  entityId: referral.entityId,
                })
              );
            }
            // Clear syncing state
            setSyncingGuestReferralsAction({ syncing: false });
          }
        });
    });
};
