import { createReducer, on } from '@ngrx/store';

import { AuthModal } from '@shared/models/auth';
import { IUser, IUserEntity } from '@shared/models/user';
import { IGraphQLError } from '@shared/models/graphql';

import {
  setLoadingAction,
  signInAction,
  signInActionSuccess,
  signInActionFailure,
  signUpAction,
  signUpActionSuccess,
  signUpActionFailure,
  signOutAction,
  setupAccountAction,
  setupAccountActionSuccess,
  setupAccountActionFailure,
  updateUserPreferenceAction,
  updateUserPreferenceActionSuccess,
  updateUserPreferenceActionFailure,
  fetchUserActionSuccess,
  fetchUserEntitiesAction,
  fetchUserEntitiesActionSuccess,
  fetchUserEntitiesActionFailure,
  clearRequestPasswordResetAction,
  requestPasswordResetAction,
  requestPasswordResetActionFinish,
  resetPasswordAction,
  resetPasswordActionFinish,
  setReferrerCodeAction,
  setLastSMSSentAction,
  updatePhoneNumberSuccessAction,
  updateProfileAction,
  updateProfileActionSuccess,
  updateProfileActionFailure,
  updatePasswordAction,
  updatePasswordActionSuccess,
  updatePasswordActionFailure,
  setAuthModal,
} from './auth.actions';

export interface AuthState {
  loading: boolean;
  errors?: IGraphQLError[];
  token?: string;
  user?: IUser;
  userEntities?: {
    loading: boolean;
    entities: IUserEntity[];
  };
  lastSMSSent?: string;
  requestPasswordReset?: {
    loading: boolean;
    email?: string;
  };
  resetPassword?: {
    loading: boolean;
  };
  updatePhoneNumberSuccess?: boolean;
  referrerCode: string | null;
  updateProfile?: {
    loading: boolean;
    errors?: IGraphQLError[];
    resource?: string;
    success: boolean;
  };
  authModal?: AuthModal;
}

export const initialState: AuthState = {
  loading: false,
  errors: undefined,
  token: undefined,
  user: undefined,
  referrerCode: null,
  authModal: undefined,
};

export const authReducer = createReducer(
  initialState,
  on(setLoadingAction, (state, action) => ({
    ...state,
    loading: action.loading,
  })),
  on(signInAction, (state) => ({ ...state, loading: true })),
  on(signInActionSuccess, (state, action) => ({
    ...state,
    errors: undefined,
    token: action.token,
    loading: false,
    user: { ...action },
  })),
  on(signInActionFailure, (state) => ({
    ...state,
    loading: false,
    errors: undefined,
  })),
  on(signUpAction, (state) => ({ ...state, loading: true })),
  on(signUpActionSuccess, (state, action) => ({
    ...state,
    errors: undefined,
    token: action.metadata?.token,
    loading: false,
    user: { ...action.result },
  })),
  on(signUpActionFailure, (state, action) => ({
    ...state,
    loading: false,
    errors: action.errors,
  })),
  on(signOutAction, (state) => ({ ...state, ...initialState })),
  on(setupAccountAction, (state) => ({ ...state, loading: true })),
  on(setupAccountActionSuccess, (state, action) =>
    state.user
      ? {
          ...state,
          loading: false,
          errors: undefined,
          user: { ...state.user, preference: { ...action } },
        }
      : { ...state, loading: false }
  ),
  on(setupAccountActionFailure, (state, action) => ({
    ...state,
    loading: false,
    errors: action.errors,
  })),
  on(updateUserPreferenceAction, (state) => ({
    ...state,
    updateProfile: {
      loading: true,
      errors: undefined,
      resource: 'preferences',
      success: false,
    },
  })),
  on(updateUserPreferenceActionSuccess, (state, action) =>
    state.user
      ? {
          ...state,
          updateProfile: {
            loading: false,
            errors: undefined,
            resource: 'preferences',
            success: true,
          },
          user: { ...state.user, preference: { ...action } },
        }
      : { ...state, updateProfile: { loading: false, success: true } }
  ),
  on(updateUserPreferenceActionFailure, (state, action) => ({
    ...state,
    updateProfile: {
      loading: false,
      errors: action.errors,
      resource: 'preferences',
      success: false,
    },
  })),
  on(fetchUserActionSuccess, (state, action) => ({
    ...state,
    loading: false,
    errors: undefined,
    user: { ...action },
  })),
  on(fetchUserEntitiesAction, (state) => ({
    ...state,
    userEntities: {
      loading: true,
      entities: state.userEntities?.entities || [],
    },
  })),
  on(fetchUserEntitiesActionSuccess, (state, action) => ({
    ...state,
    userEntities: {
      loading: false,
      entities: action.listByUser,
    },
  })),
  on(fetchUserEntitiesActionFailure, (state) => ({
    ...state,
    userEntities: {
      loading: false,
      entities: state.userEntities?.entities || [],
    },
  })),
  on(clearRequestPasswordResetAction, (state) => ({
    ...state,
    requestPasswordReset: undefined,
  })),
  on(requestPasswordResetAction, (state, action) => ({
    ...state,
    requestPasswordReset: {
      loading: true,
      email: action.email,
    },
  })),
  on(requestPasswordResetActionFinish, (state, action) => ({
    ...state,
    requestPasswordReset: {
      loading: false,
      email: action.success ? state.requestPasswordReset?.email : undefined,
    },
  })),
  on(resetPasswordAction, (state) => ({
    ...state,
    resetPassword: { loading: true },
  })),
  on(resetPasswordActionFinish, (state) => ({
    ...state,
    resetPassword: { loading: false },
  })),
  on(setReferrerCodeAction, (state, action) => ({
    ...state,
    referrerCode: action.referrerCode,
  })),
  on(setLastSMSSentAction, (state, action) => ({
    ...state,
    lastSMSSent: action.lastSMSSent,
  })),
  on(updatePhoneNumberSuccessAction, (state, action) => ({
    ...state,
    updatePhoneNumberSuccess: action.success,
    loading: false,
    user: { ...action.user },
  })),
  on(updateProfileAction, (state) => ({
    ...state,
    updateProfile: {
      loading: true,
      errors: undefined,
      resource: 'info',
      success: false,
    },
  })),
  on(updateProfileActionSuccess, (state, action) => ({
    ...state,
    updateProfile: {
      loading: false,
      errors: undefined,
      resource: undefined,
      success: true,
    },
    user: { ...action.updateProfile.result },
  })),
  on(updateProfileActionFailure, (state, action) => ({
    ...state,
    updateProfile: {
      loading: false,
      errors: action.errors,
      resource: undefined,
      success: false,
    },
  })),
  on(updatePasswordAction, (state) => ({
    ...state,
    updateProfile: {
      loading: true,
      errors: undefined,
      resource: 'password',
      success: false,
    },
  })),
  on(updatePasswordActionSuccess, (state, action) => ({
    ...state,
    updateProfile: {
      loading: false,
      errors: undefined,
      resource: 'password',
      success: true,
    },
  })),
  on(updatePasswordActionFailure, (state, action) => ({
    ...state,
    updateProfile: {
      loading: false,
      errors: action.errors,
      resource: undefined,
      success: false,
    },
  })),
  on(setAuthModal, (state, action) => ({
    ...state,
    authModal: action.authModal,
  }))
);
