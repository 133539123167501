import { Injectable } from '@angular/core';
import { catchError, map, Observable, switchMap, take } from 'rxjs';
import { Apollo } from 'apollo-angular';

import {
  FETCH_PROPERTIES,
  FETCH_PROPERTY,
} from '@graphql/queries/properties.queries';
import { PRO_FORMA_FROM_PROPERTY } from '@graphql/queries/pro-forma.queries';

import {
  IPageOfPropertyInput,
  IPageOfPropertyData,
  IGetValidPropertyData,
} from '@shared/models/properties';
import { StringPipe } from '@shared/pipes';
import { IPropertyProFormaData } from '@shared/models/pro-forma';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectAuth } from '@state/auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class PropertiesService {
  constructor(
    private apollo: Apollo,
    private store: Store<AppState>
  ) {}

  fetchProperties(
    query: IPageOfPropertyInput
  ): Observable<IPageOfPropertyData> {
    return this.store
      .select(selectAuth)
      .pipe(take(1))
      .pipe(
        switchMap((auth) =>
          this.apollo
            .query({
              query: FETCH_PROPERTIES,
              variables: {
                ...query,
                isLoggedIn: !!auth.token && !!auth.user?.confirmedAt,
              },
            })
            .pipe(map((result) => result.data))
        )
      );
  }

  fetchProperty(input: {
    id: string;
    refCode?: string;
    campaignName?: string;
    entityId?: string;
  }): Observable<IGetValidPropertyData> {
    return this.store
      .select(selectAuth)
      .pipe(take(1))
      .pipe(
        switchMap((auth) =>
          this.apollo
            .query({
              query: FETCH_PROPERTY,
              variables: {
                id: input.id,
                refCode: input.refCode,
                campaignName: input.campaignName,
                entityId: input.entityId,
                isLoggedIn: !!auth.token && !!auth.user?.confirmedAt,
              },
            })
            .pipe(map((result) => result.data))
        )
      );
  }

  fetchPropertyProForma(propertyId: string): Observable<IPropertyProFormaData> {
    return this.apollo
      .query({
        query: PRO_FORMA_FROM_PROPERTY,
        variables: { propertyId },
      })
      .pipe(
        map((result) => {
          return {
            ...result.data,
            getProFormaFromProperty: {
              ...result.data.getProFormaFromProperty,
              purchasePrice: new StringPipe().transform(
                result.data.getProFormaFromProperty.purchasePrice,
                'strPriceToDecimal'
              ),
              rentRepairEstimate: new StringPipe().transform(
                result.data.getProFormaFromProperty.rentRepairEstimate,
                'strPriceToDecimal'
              ),
              marketRent: new StringPipe().transform(
                result.data.getProFormaFromProperty.marketRent,
                'strPriceToDecimal'
              ),
              vacancy: new StringPipe().transform(
                result.data.getProFormaFromProperty.vacancy,
                'strPriceToDecimal'
              ),
              propertyTax: new StringPipe().transform(
                result.data.getProFormaFromProperty.propertyTax,
                'strPriceToDecimal'
              ),
              homeOwnersAssociationFees: result.data.getProFormaFromProperty
                .homeOwnersAssociationFees
                ? new StringPipe().transform(
                    result.data.getProFormaFromProperty
                      .homeOwnersAssociationFees,
                    'strPriceToDecimal'
                  )
                : null,
              propertyManagement: new StringPipe().transform(
                result.data.getProFormaFromProperty.propertyManagement,
                'strPriceToDecimal'
              ),
              leasingFees: new StringPipe().transform(
                result.data.getProFormaFromProperty.leasingFees,
                'strPriceToDecimal'
              ),
              insurance: new StringPipe().transform(
                result.data.getProFormaFromProperty.insurance,
                'strPriceToDecimal'
              ),
              repairsAndMaintenance: new StringPipe().transform(
                result.data.getProFormaFromProperty.repairsAndMaintenance,
                'strPriceToDecimal'
              ),
              flipRepairEstimate: new StringPipe().transform(
                result.data.getProFormaFromProperty.flipRepairEstimate,
                'strPriceToDecimal'
              ),
              afterRepairValue: new StringPipe().transform(
                result.data.getProFormaFromProperty.afterRepairValue,
                'strPriceToDecimal'
              ),
              holdingsCosts: new StringPipe().transform(
                result.data.getProFormaFromProperty.holdingsCosts,
                'strPriceToDecimal'
              ),
              listingExpenses: new StringPipe().transform(
                result.data.getProFormaFromProperty.listingExpenses,
                'strPriceToDecimal'
              ),
            },
          };
        }),
        catchError((error) => {
          throw error;
        })
      );
  }
}
