<p class="text-xs mb-1">
  Name(s) of your purchasing Entity/LLC or Individual(s) Name
</p>
<app-shared-search-multi-selector
  (searchChange)="handleSearchChange($event)"
  [options]="options"
  [selected]="selected"
  (selectedChange)="handleSelectedChange($event)"
  [loading]="loading ? 'Loading...' : undefined"
  placeholder="Enter at least 3 characters to search..."
  [errors]="errors"
  [touched]="touched"
  [disabled]="disabled"
  [yPosition]="yPosition"
></app-shared-search-multi-selector>
