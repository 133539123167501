import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';

import {
  SIGN_IN,
  SIGN_UP,
  PASSWORD_RESET_WITH_PASSWORD,
  CONFIRM_PHONE_NUMBER,
  UPDATE_PHONE_NUMBER,
} from '@graphql/mutations/auth.mutations';
import {
  FETCH_CURRENT_USER,
  FETCH_CURRENT_USER_ENTITIES,
  REQUEST_PASSWORD_RESET,
  SEND_PHONE_NUMBER_CONFIRMATION,
} from '@graphql/queries/auth.queries';

import {
  ISignInInput,
  ISignInRes,
  ISignUpInput,
  ISignUpRes,
  IFetchUserData,
  IFetchUserEntitiesData,
  IPasswordResetWithPasswordInput,
  ISendPhoneNumberConfirmationData,
  IConfirmNumberData,
  IConfirmNumberInput,
  IRequestPasswordReset,
  IPasswordResetWithPasswordData,
} from '@shared/models/auth';
import {
  IUpdatePhoneNumberInput,
  IUpdatePhoneNumberData,
} from '@shared/models/profile';
import {
  IUpdateUserPreferenceData,
  IUserPreferenceData,
  IUserPreferenceInput,
} from '@shared/models/user';
import {
  CREATE_USER_PREFERENCE,
  UPDATE_USER_PREFERENCE,
} from '@graphql/mutations/user.mutation';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private apollo: Apollo) {}

  signInUser(input: ISignInInput): Observable<ISignInRes> {
    return this.apollo
      .mutate({
        mutation: SIGN_IN,
        variables: { ...input },
      })
      .pipe(map((result) => result.data as ISignInRes));
  }

  signUpUser(input: ISignUpInput): Observable<ISignUpRes> {
    return this.apollo
      .mutate({
        mutation: SIGN_UP,
        variables: input,
      })
      .pipe(map((result) => result.data as ISignUpRes));
  }

  createUserPreference(
    input: IUserPreferenceInput
  ): Observable<IUserPreferenceData> {
    return this.apollo
      .mutate({
        mutation: CREATE_USER_PREFERENCE,
        variables: input,
      })
      .pipe(map((result) => result.data as IUserPreferenceData));
  }

  updateUserPreference(
    id: string,
    input: IUserPreferenceInput['input']
  ): Observable<IUpdateUserPreferenceData> {
    return this.apollo
      .mutate({
        mutation: UPDATE_USER_PREFERENCE,
        variables: { id, input: input },
      })
      .pipe(map((result) => result.data as IUpdateUserPreferenceData));
  }

  fetchUser(): Observable<IFetchUserData> {
    return this.apollo
      .query({
        query: FETCH_CURRENT_USER,
      })
      .pipe(map((result) => result.data));
  }

  fetchUserEntities(userId: string): Observable<IFetchUserEntitiesData> {
    return this.apollo
      .query({
        query: FETCH_CURRENT_USER_ENTITIES,
        variables: { userId },
      })
      .pipe(map((result) => result.data));
  }

  requestPasswordReset(email: string): Observable<IRequestPasswordReset> {
    return this.apollo
      .query({
        query: REQUEST_PASSWORD_RESET,
        variables: { email },
      })
      .pipe(map((result) => result.data as IRequestPasswordReset));
  }

  passwordResetWithPassword(
    input: IPasswordResetWithPasswordInput
  ): Observable<IPasswordResetWithPasswordData> {
    return this.apollo
      .mutate({
        mutation: PASSWORD_RESET_WITH_PASSWORD,
        variables: { email: input.email, input: input.input },
      })
      .pipe(map((result) => result.data as IPasswordResetWithPasswordData));
  }

  sendPhoneNumberConfirmation(): Observable<ISendPhoneNumberConfirmationData> {
    return this.apollo
      .query({
        query: SEND_PHONE_NUMBER_CONFIRMATION,
      })
      .pipe(map((result) => result.data as ISendPhoneNumberConfirmationData));
  }

  confirmPhoneNumber(
    input: IConfirmNumberInput
  ): Observable<IConfirmNumberData> {
    return this.apollo
      .mutate({
        mutation: CONFIRM_PHONE_NUMBER,
        variables: input,
      })
      .pipe(map((result) => result.data as IConfirmNumberData));
  }

  updatePhoneNumber(
    input: IUpdatePhoneNumberInput
  ): Observable<IUpdatePhoneNumberData> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PHONE_NUMBER,
        variables: input,
      })
      .pipe(map((result) => result.data as IUpdatePhoneNumberData));
  }
}
