import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  OnInit,
  OnChanges,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceDetectorService } from 'ngx-device-detector';

import { translateInputError } from '@core/utils/errors';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shared-search-multi-selector',
  templateUrl: './search-multi-selector.component.html',
  styleUrls: ['./search-multi-selector.component.scss'],
})
export class SearchMultiSelectorComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() loading: string | undefined;
  @Input() placeholder: string | undefined;
  @Input({ required: true }) options: {
    key: string;
    value: string;
    desc?: string;
  }[] = [];
  @Input({ required: true }) selected: { key: string; value: string }[] = [];

  @Input() yPosition: 'above' | 'below' = 'below';
  @Input() touched?: boolean = false;
  @Input() errors?: ValidationErrors | null;
  @Input() disabled: boolean = false;

  @Output() searchChange = new EventEmitter<string>();
  @Output() selectedChange = new EventEmitter<
    { key: string; value: string }[]
  >();

  @ViewChild('triggerEl') triggerEl!: ElementRef;
  @ViewChild(MatMenuTrigger, { static: false }) trigger!: MatMenuTrigger;
  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;

  protected opened = false;
  protected focused = false;
  protected isMobile = false;
  protected searchValue = '';
  protected errorMessage?: string;

  constructor(private deviceService: DeviceDetectorService) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
  }

  ngAfterViewInit() {
    this.trigger.menuOpened.pipe(untilDestroyed(this)).subscribe(() => {
      this.opened = true;
      this.setMenuWidthPosition();
    });
    this.trigger.menuClosed.pipe(untilDestroyed(this)).subscribe(() => {
      this.opened = false;
    });
  }

  ngOnChanges(): void {
    this.handleError();
  }

  handleError() {
    if (this.errors) {
      this.errorMessage = translateInputError(this.errors);
    }
  }

  onSearchChange(value: string) {
    if (value.trim().length > 0 && !this.opened) {
      this.trigger.openMenu();
    }
    this.searchValue = value;
    this.searchChange.emit(value);
  }

  /**
   * Focus on input element when a key is pressed or
   * add the search value to the selected options when the Enter key is pressed
   */
  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (this.disabled) return;
    /**
     * If not loading, verify if entry is already
     * present in the selected options and add it if not
     */
    if (event.key === 'Enter' || event.key === 'Return') {
      event.preventDefault();
      if (!this.loading && this.searchValue.trim().length > 0) {
        this.addCustomValue();
      }
    }
    // Focus on the input when a key is pressed
    if (this.opened) {
      if (event.key.match(/^[a-zA-Z0-9]$/)) {
        this.inputElement.nativeElement.focus();
      }
    }
  }

  /**
   * It adds a custom value with "-" as key if value is
   * not already present
   */
  addCustomValue() {
    if (this.disabled) return;
    const exists = this.selected.some(
      (option) => option.key === '-' && option.value === this.searchValue
    );
    if (!exists) {
      this.selected.push({ key: '-', value: this.searchValue });
      this.selectedChange.emit(this.selected);
    }
  }

  toggleValue(key: string, value?: string) {
    if (this.disabled) return;
    if (key && value) {
      this.selected.push({ key, value });
    } else {
      const index = this.selected.findIndex(
        (selectedOption) => selectedOption.key === key
      );
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        const option = this.options.find((opt) => opt.key === key);
        if (option) {
          this.selected.push(option);
        }
      }
    }
    this.handleOnChange();
  }

  removeValue(key: string) {
    if (this.disabled) return;
    const index = this.selected.findIndex(
      (selectedOption) => selectedOption.key === key
    );
    if (index > -1) {
      this.selected.splice(index, 1);
    }
    this.handleOnChange();
  }

  isSelected(key: string): boolean {
    return this.selected.some((selectedOption) => selectedOption.key === key);
  }

  handleOnChange() {
    this.selectedChange.emit(this.selected);
    this.setMenuWidthPosition();
  }

  handleClick(event: MouseEvent): void {
    let classList: string[] = [];
    let targetElement: Element | null = event.target as Element;
    while (targetElement && targetElement !== this.triggerEl.nativeElement) {
      classList = classList.concat(Array.from(targetElement.classList));
      targetElement = targetElement.parentElement;
    }
    if (classList.includes('remove-icon')) {
      this.trigger.closeMenu();
      return;
    }
    this.focused = true;
    this.trigger.openMenu();
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 500);
  }

  setMenuWidthPosition() {
    const width = this.triggerEl.nativeElement.offsetWidth;
    const overlayPane = document.querySelector(
      '.cdk-overlay-pane'
    ) as HTMLElement;
    if (overlayPane) {
      overlayPane.style.width = `${width}px`;
    }
    setTimeout(() => {
      this.trigger.updatePosition();
    }, 10);
  }
}
