import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import { IListSubmarketsData } from '@shared/models/account-preferences';
import { LIST_SUBMARKETS } from '@graphql/queries/account-preferences.queries';

@Injectable({
  providedIn: 'root',
})
export class AccountPreferencesService {
  constructor(private apollo: Apollo) {}

  listSubmarkets(): Observable<IListSubmarketsData> {
    return this.apollo
      .query({
        query: LIST_SUBMARKETS,
      })
      .pipe(map((result) => result.data));
  }
}
