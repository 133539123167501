import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  IAllInvestorsData,
  IAllInvestorsInput,
} from '@shared/models/investors';
import { InvestorsService } from '@core/services/investors.service';

const currentYear = new Date().getFullYear();

@UntilDestroy()
@Component({
  selector: 'app-account-setup-modal-form-investor',
  templateUrl: './investor.component.html',
})
export class AccountSetupInvestorComponent implements OnInit {
  @Input() selected: { key: string; value: string }[] = [];
  @Input() disabled: boolean = false;
  @Input() touched?: boolean = false;
  @Input() errors?: ValidationErrors | null;
  @Input() yPosition: 'above' | 'below' = 'above';
  @Output() selectedChange = new EventEmitter<
    { key: string; value: string }[]
  >();

  private searchTerms = new Subject<string>();
  protected loading = false;
  protected options: { key: string; value: string }[] = [];
  protected investors$: Observable<IAllInvestorsData> = of({
    allInvestors: [],
  });
  protected errorMessage?: string;

  constructor(private investorsService: InvestorsService) {}

  ngOnInit() {
    this.investors$ = this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term.length >= 3) {
          return this.fetchInvestors(term).pipe(
            tap((data) => {
              this.options = data.allInvestors.map((investor) => ({
                key: investor.id,
                value: investor.fullName,
                desc:
                  `${currentYear} Purchases: ` +
                  (investor.currentYearBuyRecords || 0) +
                  ` &nbsp;|&nbsp; ${currentYear - 1} Purchases: ` +
                  (investor.lastYearBuyRecords || 0),
              }));
              this.loading = false;
            }),
            catchError((error) => {
              this.loading = false;
              return of({ allInvestors: [] });
            })
          );
        } else {
          // No need to set loading = false here; it's handled in handleSearchChange
          return of({ allInvestors: [] });
        }
      })
    );
    // Subscribe to activate the observable chain
    this.investors$.pipe(untilDestroyed(this)).subscribe();
  }

  handleSearchChange(value: string) {
    const trimmedValue = value.trim();
    if (trimmedValue.length >= 3) {
      this.loading = true;
      this.options = [];
    } else {
      this.loading = false;
      this.options = [];
    }
    this.searchTerms.next(trimmedValue);
  }

  private fetchInvestors(term: string): Observable<IAllInvestorsData> {
    const input: IAllInvestorsInput = { fullName: term };
    return this.investorsService.allInvestors(input);
  }

  handleSelectedChange(selected: { key: string; value: string }[]) {
    this.selected = selected;
    this.selectedChange.emit(selected);
  }
}
