import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  IAllInvestorsInput,
  IAllInvestorsData,
} from '@shared/models/investors';

export const ALL_INVESTORS: TypedDocumentNode<
  IAllInvestorsData,
  IAllInvestorsInput
> = gql`
  query allInvestors($fullName: String!) {
    allInvestors(fullName: $fullName) {
      id
      fullName
      currentYearBuyRecords
      lastYearBuyRecords
    }
  }
`;
