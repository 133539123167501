import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import { ALL_INVESTORS } from '@graphql/queries/investors.queries';
import {
  IAllInvestorsInput,
  IAllInvestorsData,
} from '@shared/models/investors';

@Injectable({
  providedIn: 'root',
})
export class InvestorsService {
  constructor(private apollo: Apollo) {}

  allInvestors(input: IAllInvestorsInput): Observable<IAllInvestorsData> {
    return this.apollo
      .query({
        query: ALL_INVESTORS,
        variables: input,
      })
      .pipe(map((result) => result.data));
  }
}
