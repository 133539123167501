import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  IUpdateUserPreferenceData,
  IUserPreferenceData,
  IUserPreferenceInput,
} from '@shared/models/user';

import { MUTATION_ERROR_FRAGMENT } from '../errors.fragments';

export const USER_PREFERENCE_FRAGMENT = gql`
  fragment UserPreferenceFragment on UserPreference {
    __typename
    id
    roles
    markets
    investmentStyles
    rehabComfortabilities
  }
`;

export const CREATE_USER_PREFERENCE: TypedDocumentNode<
  IUserPreferenceData,
  IUserPreferenceInput
> = gql`
  ${USER_PREFERENCE_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation createUserPreference($input: CreateUserPreferenceInput!) {
    createUserPreference(input: $input) {
      result {
        ...UserPreferenceFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;

export const UPDATE_USER_PREFERENCE: TypedDocumentNode<
  IUpdateUserPreferenceData,
  IUserPreferenceInput
> = gql`
  ${USER_PREFERENCE_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation updateUserPreference($id: ID!, $input: UpdateUserPreferenceInput!) {
    updateUserPreference(id: $id, input: $input) {
      result {
        ...UserPreferenceFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;
