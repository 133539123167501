<div
  class="multi-selector"
  [ngClass]="{
    opened: opened,
    errors: errors && errorMessage && (touched || internalTouched),
    disabled: disabled
  }"
  #triggerEl
  [matMenuTriggerFor]="!disabled ? multiSelector : null"
  (click)="handleClick($event)"
  tabindex="0"
  (focus)="handleFocus($event)"
  (blur)="onBlur($event)"
>
  <label
    *ngIf="label"
    class="global-input-label"
    [ngClass]="{ active: focused || (selected && selected.length) }"
    >{{ label }}</label
  >
  <app-shared-icon
    *ngIf="!selected?.length"
    name="chevron-down-2"
    [size]="18"
  ></app-shared-icon>
  <div class="input" [ngClass]="{ 'has-values': !!selected?.length }">
    <div
      *ngIf="selected && selected.length"
      class="selected"
      [ngClass]="{ 'no-label': !label }"
    >
      <ng-container *ngFor="let option of options">
        <div class="value" *ngIf="selected.includes(option.key)">
          <span>{{ option.value }}</span>
          <app-shared-icon
            name="close"
            class="remove-icon"
            [size]="8"
            (click)="removeValue(option.key)"
          ></app-shared-icon>
        </div>
      </ng-container>
    </div>
  </div>

  <mat-menu #multiSelector="matMenu" class="options-dropdown">
    <div class="options-dropdown-wrapper" (click)="$event.stopPropagation()">
      <div
        *ngIf="searchable"
        class="search-container"
        (click)="$event.stopPropagation()"
      >
        <input
          placeholder="Search options..."
          (click)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()"
          [(ngModel)]="searchTerm"
          (input)="onSearchChange()"
        />
      </div>
      <ul>
        <li
          *ngFor="let option of filteredOptions"
          [ngClass]="{ selected: selected && selected.includes(option.key) }"
          (click)="toggleValue(option.key); $event.stopPropagation()"
        >
          {{ option.value }}
          <app-shared-icon
            name="check"
            class="check-icon"
            [size]="20"
            *ngIf="selected && selected.includes(option.key)"
          ></app-shared-icon>
        </li>
      </ul>
      <div class="empty-results" *ngIf="searchable && !filteredOptions.length">
        No options found
      </div>
    </div>
  </mat-menu>
</div>

<div
  *ngIf="errors && errorMessage && (touched || internalTouched)"
  class="input-error-message multi-selector-error"
>
  {{ errorMessage }}
</div>
